body {
    font-size: 10pt;
    font-family: Arial, Helvetica, sans-serif;
}

.sig-canvas {
    border: 1px solid black;
}

h1 {
    font-size: 14pt;
}

h2 {
    font-size: 13pt;
}

.pdf .page {
    font-size: 8pt;
    margin: 20pt;
    max-width: 400pt;
    page-break-after: always;
    line-height: 1.5;
}

.pdf .page p.header {
    margin-bottom: 5pt;
}

.pdf input {
    border: none;
    border-bottom: 1px solid black;
    padding: 1px 5px;
    text-transform: uppercase;
}
